import Swal from "sweetalert2";
import emailjs from "emailjs-com";

export type EmailData = {
  name?: string;
  role?: string;
  state?: string;
  country?: string;
  email: string;
  city?: string;
  club?: string;
  hearAbout?: string;
};
export const sendEmail = (data?: EmailData) => {
    
  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const userId = process.env.REACT_APP_EMAILJS_USER_ID;

  if (!serviceId || !templateId) return;
  const template = {
    name: data?.name,
    role: data?.role,
    email: data?.email,
    country: data?.country,
    city: data?.city,
    club: data?.club,
    hearAbout: data?.hearAbout,
  };
  return emailjs
    .send(serviceId, templateId, template, userId)
    .then((response) => {
      Swal.fire({
        icon: "success",
        title: "Email sent",
        text: "Your application has been submitted successfully!",
      });
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Email Failed",
        text: "Something went wrong while sending your application",
      });
    });
};
