import React, { useEffect, useMemo, useState } from "react";
import {
  USER_ERROR_CLEAR,
  USER_SUCCESS_CLEAR,
} from "../../Store/types/userType";
import { useDispatch, useSelector } from "react-redux";

import Select from "react-select";
import Swal from "sweetalert2";
import { USER_SIGNUP_ERROR } from "../../Store/types/userType";
import countryList from "react-select-country-list";
import emailjs from "emailjs-com";
import { userSignup } from "../../Store/action/userAction.ts";

export default function Modal() {
  const dispatch = useDispatch();

  const { error, successMessage } = useSelector((state) => state.users);

  const [country, setCountry] = useState("");
  const countryOptions = useMemo(() => countryList().getData(), []);
  const countryChangeHandler = (country) => {
    setCountry(country);
  };

  const [role, setRole] = useState("");

  const roleOptions = [
    { value: "CAdmin", label: "Club" },
    { value: "Coach", label: "Coach" },
    { value: "Scout", label: "Scout" },
    { value: "Other", label: "Other" },
  ];

  const roleChangeHandler = (role) => {
    setRole(role);
  };

  const [hearabout, setHearAbout] = useState("");

  const hearaboutOptions = [
    {
      value: "Online Advertisement",
      label: "I saw an advertisement for SportoJet while browsing the internet",
    },
    {
      value: "Social Media",
      label:
        "I came across SportoJet on social media platforms like Facebook, Instagram, or Twitter",
    },
    {
      value: "Word of Mouth",
      label: "A friend or colleague recommended SportoJet to me",
    },
    {
      value: "Online Review",
      label: "I read positive reviews about SportoJet on a website or forum",
    },
    {
      value: "Search Engine",
      label:
        "I found SportoJet while searching for sports-related products on Google or another search engine",
    },
    {
      value: "Sports Event",
      label:
        "I learned about SportoJet through a sports event, tournament, or competition",
    },
    {
      value: "Influencer Endorsement",
      label:
        "An influencer or athlete I follow mentioned SportoJet in their content",
    },
    {
      value: "Email Newsletter",
      label: "I received an email newsletter that featured SportoJet",
    },
    {
      value: "Retail Store",
      label:
        "I saw SportoJet being sold or advertised at a sports equipment store",
    },
    {
      value: "Podcast",
      label:
        "I heard about SportoJet while listening to a sports-related podcast",
    },
    {
      value: "TV Commercial",
      label: "I saw a commercial for SportoJet while watching TV",
    },
    {
      value: "Blog Post",
      label: "I read a blog post that discussed or reviewed SportoJet",
    },
    {
      value: "App Store",
      label:
        "I found SportoJet listed on the app store while looking for sports-related apps",
    },
    {
      value: "Sports Club or Team",
      label: "I learned about SportoJet through a local sports club or team",
    },
    {
      value: "Online Community",
      label:
        "I discovered SportoJet through discussions on online sports communities or forums",
    },
  ];

  const hearaboutChangeHandler = (hearabout) => {
    setHearAbout(hearabout);
  };

  let obj = {
    name: "",
    role: "",
    email: "",
    Cuntry: "",
    City: "",
    Club: "",
    hearAbout: "",
  };

  const [state, setState] = useState(obj);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false)


  const handelChange = (name, value) => {

      setState(prev => {
        return {
          ...prev,
          [name]: value
        }
      });
  };

  const UserCreate = () => {
    let objs = {
      name: `${state.name.split(" ")[0]} ${state.name.split(" ")[1] ?? ""}`,
      role: role && role.value ? role.value : "",
      email: state.email,
      country: country.label,
      city: state.City,
      club: state.Club,
      hearAbout: hearabout && hearabout.value ? hearabout.value : "",
    };
    
    if (isTermsAccepted) {
      dispatch(userSignup(objs));
  
      return;
    }

  };

  // useEffect(() => {
  //   if (successMessage) {
  //     Swal.fire({
  //       // position: 'top-end',
  //       icon: 'success',
  //       title: successMessage,
  //       showConfirmButton: false,
  //       showClass: {
  //         popup: 'animate__animated animate__fadeInDown'
  //       },
  //       hideClass: {
  //         popup: 'animate__animated animate__fadeOutUp'
  //       },
  //       timer: 2500
  //     }).then(() => {
  //       dispatch({ type: USER_SUCCESS_CLEAR })
  //       // navigate('/dashboard/default');
  //     })
  //   }
  //   if (error) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Oops...',
  //       showClass: {
  //         popup: 'animate__animated animate__fadeInDown'
  //       },
  //       hideClass: {
  //         popup: 'animate__animated animate__fadeOutUp'
  //       },
  //       text: error
  //     }, 200).then(() => {
  //       dispatch({ type: USER_ERROR_CLEAR })
  //     })
  //   }
  // }, [error, successMessage])

  // console.log(country)

  return (
    <>
      <div
        className="modal fade"
        id="joinModal"
        tabIndex="-1"
        aria-labelledby="joinModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="joinModalLabel">
                Apply for beta
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  {/* <label htmlFor="name" className="form-label">
                    Name
                  </label> */}
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={state.name}
                    aria-describedby="nameHelp"
                    placeholder="Name"
                    onChange={(e) =>
                      handelChange(e.target.name, e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  {/* <label htmlFor="role" className="form-label">
                    Role
                  </label> */}
                  <Select
                    id="role"
                    class="form-select"
                    options={roleOptions}
                    value={role}
                    onChange={roleChangeHandler}
                    placeholder="Select Role"
                  />
                </div>
                <div className="mb-3">
                  {/* <label htmlFor="email" className="form-label">
                    Email
                  </label> */}
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={state.email}
                    aria-describedby="emailHelp"
                    placeholder="Email"
                    onChange={(e) =>
                      handelChange(e.target.name, e.target.value)
                    }
                  />
                  <div id="emailHelp" className="form-text">
                    We'll never share your email with anyone else.
                  </div>
                </div>
                <div className="mb-3">
                  {/* <label htmlFor="country" className="form-label">
                    Country
                  </label> */}
                  <Select
                    id="country"
                    class="form-select"
                    options={countryOptions}
                    value={country}
                    onChange={countryChangeHandler}
                    placeholder="Select Country"
                  />
                </div>
                <div className="mb-3">
                  {/* <label htmlFor="city" className="form-label">
                    City
                  </label> */}
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="City"
                    value={state.City}
                    aria-describedby="cityHelp"
                    placeholder="City"
                    onChange={(e) =>
                      handelChange(e.target.name, e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  {/* <label htmlFor="club" className="form-label">
                    Club / Team
                  </label> */}
                  <input
                    type="text"
                    className="form-control"
                    id="club"
                    name="Club"
                    value={state.Club}
                    aria-describedby="clubHelp"
                    placeholder="Club / Team"
                    onChange={(e) =>
                      handelChange(e.target.name, e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  {/* <label htmlFor="hearabout" className="form-label">
                    How did you hear about Sportojet?
                  </label> */}
                  <Select
                    id="hearabout"
                    class="form-select"
                    options={hearaboutOptions}
                    value={hearabout}
                    onChange={hearaboutChangeHandler}
                    placeholder="How did you hear about Sportojet?"
                  />
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="terms"
                    name="isCheck"
                    checked={state.isCheck}
                    onChange={(e) =>
                      setIsTermsAccepted(prev => !prev)
                    }
                  />
                  <label className="form-check-label" htmlFor="terms">
                    I accept the terms of service.
                  </label>
                </div>
                <button
                  data-bs-dismiss="modal"
                  type="button"
                  onClick={() => UserCreate()}
                  disabled={!isTermsAccepted}
                  className="btn btn-outline-success join-beta"
                >
                  Apply for beta
                </button>
              </form>
            </div>
            {/* <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
