import { EmailData, sendEmail } from "./utils/sendMail.ts";
import { USER_ERROR_CLEAR, USER_SIGNUP_ERROR, USER_SIGNUP_SUCCESS, USER_SUCCESS_CLEAR } from "../types/userType"

import { SERVER_URI } from "../../Config/keys";
import axios from 'axios';

export const userSignup = (data:EmailData) => {
    return async (dispatch) => {
        try {
            const response = await sendEmail(data);
            
            dispatch({
                type: USER_SIGNUP_SUCCESS,
                payload: {
                    // @ts-ignore 
                    successMessage: response?.data?.message,
                    // token : response.data.token
                }
            })

        } catch (error) {
            let data = error?.response?.data?.message
            dispatch({
                type: USER_SIGNUP_ERROR,
                payload: {
                    error: data
                }
            })
        }
    }
}
